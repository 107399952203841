<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header-inner">
        <form
          @submit.prevent="(page = 0), getProductQuote()"
          @keydown.enter="keyword ? ((page = 0), getProductQuote()) : null"
        >
          <div class="filter-card">
            <div class="filter-field">
              <label>商品查詢</label>
              <input type="text" required placeholder="請輸入關鍵字" v-model="keyword" />
            </div>

            <button type="submit" class="btn btn-primary btn-sm ml-1">查詢</button>
            <button type="button" class="btn btn-warning btn-sm ml-2" @click="goBack">
              返回
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="page-body">
      <div class="table-wrap">
        <el-table :data="productList" :header-cell-style="{fontWeight: 600}" style="width: 100%">
          <el-table-column align="center" width="20px" />
          <el-table-column label="商品">
            <template slot-scope="scope">
              {{ scope.row.parT_NOX }} {{ scope.row.producT_NAME }}
            </template>
          </el-table-column>

          <el-table-column align="right" prop="stoR_UPC" label="建議售價" />
          <el-table-column align="right" prop="heaD_UPC" label="最低售價" />
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="chooseProduct(scope.row)"
              >
                報價
              </button>
            </template>
          </el-table-column>
          <el-table-column slot="empty">暫無數據</el-table-column>
        </el-table>

        <!-- 分頁 -->
        <el-pagination
          v-show="count > 0"
          layout="prev, pager, next"
          :total="count"
          @current-change="changePage"
        />
      </div>
      <div v-if="quotation.length > 0" class="quotation-count-btn-wrap" style="z-index: 999">
        <div class="inner">
          <div class="btn btn-primary" data-toggle="modal" data-target="#quotationModal">
            <span>送出報價 ({{ quotation.length }})</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 商品報價 Modal-->
    <div
      class="modal fade"
      id="productQuoteModal"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="text-center">
              {{ quoteProduct.producT_NAME }}<br />
              {{ quoteProduct.parT_NOX }}
            </h6>
          </div>
          <div class="modal-body">
            <div class="row mt-2" style="font-weight: 600">
              <div class="col text-right">
                建議售價：
              </div>
              <div class="col text-left">
                {{ quoteProduct.stoR_UPC }}
              </div>
            </div>

            <div class="row mt-3" style="font-weight: 600">
              <div class="col text-right">
                最低售價：
              </div>
              <div class="col text-left">
                {{ quoteProduct.heaD_UPC }}
              </div>
            </div>

            <div class="row mt-4">
              <div class="col text-right">
                欲報價金額
              </div>
              <div class="col">
                <input type="text" class="money-input text-center" min="0" v-model="quotePrice" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              關閉
            </button>
            <button type="button" class="btn btn-primary" @click="addProduct">
              報價
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 報價列表 Modal -->
    <div class="modal fade" id="quotationModal" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6>
              請確認品項與數量
              <p class="mt-1">共 {{ quotation.length }} 品項</p>
            </h6>
          </div>
          <div class="modal-body">
            <div class="cart-product-list">
              <div v-for="(product, index) in quotation" :key="index" class="product">
                <div class="product-info text-right">
                  <h6 class="product-name">
                    <strong>{{ product.parT_NOX }} {{ product.producT_NAME }}</strong>
                  </h6>
                  <div class="row">
                    <div class="col-8 text-right">
                      建議售價
                    </div>
                    <div class="col-4">
                      {{ product.stoR_UPC }}
                    </div>
                    <div class="col-8">
                      最低售價
                    </div>
                    <div class="col-4">
                      {{ product.heaD_UPC }}
                    </div>
                    <div class="col-8">
                      報價金額
                    </div>
                    <div class="col-4">
                      {{ product.upcX_AMT }}
                    </div>
                  </div>
                </div>
                <div class="delete">
                  <eva-icon name="trash-2-outline" fill="#FFF" @click="deleteProduct(product)" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              關閉
            </button>
            <button
              v-if="quotation.length > 0"
              type="button"
              class="btn btn-primary"
              @click="sendQuotation"
            >
              確認送出
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import { mapActions } from "vuex";
import { apiGetProductQuote, apiSendQuotation } from "@/apis/quote";

export default {
  name: "ProductQuote",
  data() {
    return {
      // 報價客戶資料
      customerQuote: {},

      // 關鍵字
      keyword: "",
      // 商品列表
      productList: [],
      // 商品總數
      count: 0,
      // 分頁
      page: 0,

      // 報價商品
      quoteProduct: {},

      // 點擊次數
      hitCount: 0,
      // 報價價格
      quotePrice: "",
      // 報價列表
      quotation: []
    };
  },
  methods: {
    // 送出報價
    sendQuotation() {
      this.updateLoading(true);
      const customerQuote = JSON.parse(localStorage.CUSTOMER_QUOTE);
      const quotation = this.quotation.map((item, index) => {
        return {
          useR_GUID: localStorage.USER_GUID,
          cusT_ID: customerQuote.cusT_ID,
          stoR_CDX: customerQuote.stoR_CDX,
          docX_TYP: customerQuote.docX_TYP,
          docX_NOX: customerQuote.docX_NOX,
          iteM_XXX: ++index,
          parT_NOX: item.parT_NOX,
          producT_NAME: item.producT_NAME,
          upcX_AMT: item.upcX_AMT
        };
      });
      apiSendQuotation(quotation)
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            localStorage.setItem("QUOTATION_MESSAGE", response.data);
            $("#quotationModal").modal("hide");
            this.$router.push({ path: "finish" });
          }
        })
        .catch(() => {});
    },

    // 加入報價商品
    addProduct() {
      if (this.quotePrice) {
        Number(this.quotePrice) < Number(this.quoteProduct.heaD_UPC)
          ? ((this.quotePrice = this.quoteProduct.heaD_UPC), (this.hitCount = 1))
          : (this.hitCount = 0);

        this.quotation = [];
        if (localStorage.QUOTATION) {
          this.quotation = JSON.parse(localStorage.QUOTATION);
        }

        // 商品資訊
        const product = {
          parT_NOX: this.quoteProduct.parT_NOX, // 商品編號
          producT_NAME: this.quoteProduct.producT_NAME, // 商品名稱
          stoR_UPC: this.quoteProduct.stoR_UPC, // 建議售價
          heaD_UPC: this.quoteProduct.heaD_UPC, // 最低售價
          upcX_AMT: Number.parseFloat(this.quotePrice).toFixed(2) // 報價金額
        };

        if (this.hitCount === 0) {
          if (this.quotation.length > 0) {
            let sameProduct = this.quotation.findIndex(
              element => element.parT_NOX === product.parT_NOX
            );
            sameProduct >= 0
              ? (this.quotation[sameProduct] = product)
              : this.quotation.push(product);
          } else {
            this.quotation.push(product);
          }

          localStorage.setItem("QUOTATION", JSON.stringify(this.quotation));
          $("#productQuoteModal").modal("hide");
        }
      }
    },

    // 刪除報價商品
    deleteProduct(product) {
      let sameProduct = this.quotation.findIndex(element => element.parT_NOX === product.parT_NOX);
      this.quotation.splice(sameProduct, 1);

      localStorage.setItem("QUOTATION", JSON.stringify(this.quotation));
    },

    // 選擇商品報價
    chooseProduct(product) {
      this.quoteProduct = product;
      if (this.quotation.length > 0) {
        let sameProduct = this.quotation.findIndex(
          element => element.parT_NOX === product.parT_NOX
        );
        sameProduct >= 0
          ? (this.quotePrice = this.quotation[sameProduct].upcX_AMT)
          : (this.quotePrice = "");
      } else {
        this.quotePrice = "";
      }
      $("#productQuoteModal").modal("show");
    },

    // 返回
    goBack() {
      localStorage.removeItem("CUSTOMER_QUOTE");
      localStorage.removeItem("QUOTATION_MESSAGE");
      this.$router.push({ path: "customer-quote" });
    },

    // 換頁
    changePage(page) {
      this.page = --page;
      this.getProductQuote();
    },

    // 取得報價商品
    getProductQuote() {
      this.updateLoading(true);
      apiGetProductQuote({
        ...this.customerQuote,
        keyword: this.keyword,
        pageIndex: this.page
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.count = response.data.count;
            this.productList = response.data.datas.map(item => {
              return {
                parT_NOX: item.parT_NOX,
                producT_NAME: item.producT_NAME,
                stoR_UPC: item.stoR_UPC,
                heaD_UPC: item.heaD_UPC
              };
            });
          }
        });
    },

    // loading
    ...mapActions(["updateLoading"])
  },

  created() {
    this.customerQuote = JSON.parse(localStorage.CUSTOMER_QUOTE);
    // 取得購物車內商品
    if (localStorage.QUOTATION) {
      this.quotation = JSON.parse(localStorage.QUOTATION);
    }
  }
};
</script>

<style lang="scss" scoped>
.money-input {
  width: 60%;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}
.money-input:focus {
  outline: none;
}
.quotation-count-btn-wrap {
  text-align: center;
  position: sticky;
  bottom: 10px;

  .inner {
    background-color: #e60012;
    display: inline-block;
    padding: 5px;
    font-size: 10px;
    border-radius: 10px;
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.15);
  }
}

/deep/ .el-table, .el-table__expanded-cell {
  font-weight: 600;
}
</style>
