import req from './https';

// 取得可報價客戶
export const apiGetCustomerQuote = (params) => req('post', '/QuoteWeb/postSalNoxQuote', params);

// 取得可報價商品
export const apiGetProductQuote = (params) => req('post', '/QuoteWeb/postQuoteProduct', params);

// 變更可送貨日
export const apiUpdateWeekday = (params) => req('post', '/Quote/postSetWeekDay', params);

// 送出報價單
export const apiSendQuotation = (params) => req('post', '/Quote/postQuoteProductOrder', params);
