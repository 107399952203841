
import { Notification } from'element-ui'
import router from '@/router/index'


// 錯誤msg消息提示
export const tip = msg => {
    Notification({
        title: "錯誤",
        message: msg.message ? msg.message : msg,
        type: 'error',
        duration: 3500
    });
}

//  跳轉回首頁，攜帶當前頁面路由，登入完成後跳轉回原本頁面
export const toLogin = () => {
    router.push('/login')
}

